
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUploadList from "@/components/scope/file-upload-list.vue";
import Template from "@/views/dashboard/template.vue";

const app = namespace("app");
const base = namespace("base");
const userTask = namespace("userTask");

@Component({
  components: {Template, FileUpload, Editor, FileUploadList }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @app.Mutation setPBPX;
  @userTask.Action userTaskSave;
  @userTask.Action userTaskDetail;

  emptyToLine = emptyToLine;
  dayjs = dayjs;

  get breadData() {
    return [
      { name: "用户任务配置", path: "/userTask/conf/index" },
      {
        name: "查看任务"
      }
    ];
  }
  taskTypeList: any = [
    {
      label: "新手任务",
      value: 1
    },
    {
      label: "日常任务",
      value: 2
    }
  ];
  conditionTypeList: any = [
    // {
    //   label: "固定城市",
    //   value: 1
    // },
    {
      label: "固定用户",
      value: 2
    },
    {
      label: "新老用户",
      value: 3
    }
  ]

  created() {
    this.init();
  }
  get taskNo() {
    return this.$route.params.taskNo;
  }

  taskDetail: any = {};

  init() {
    // 获取详情
    if(this.taskNo){
      this.userTaskDetail({taskNo: this.taskNo}).then(res => {
        this.taskDetail = res.data;
        this.taskDetail.iconList = [{url: res.data['icon']}];
        if (this.taskDetail.conditionGroups) {
          this.taskDetail.conditionGroups.forEach(group => {
            group.forEach(condition => {
              if (condition.type == 2) {
                let fileUrl = condition.value;
                let  fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                condition.fileList = [{name: fileName, url: fileUrl}]
              }
            })
          })
        }
      })
    }
  }
  mounted() {}
}
